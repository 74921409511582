import { Link } from "gatsby"
import styled from "styled-components"

const StyledLink = styled(Link)`
  text-decoration: none;
  color: #f53cf5;
  font-weight: bold;
  border-bottom: 3px dotted magenta;
`
export default StyledLink
