import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAmazon } from "@fortawesome/free-brands-svg-icons"
import { Link } from "rebass"

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  zoom: 2;
  vertical-align: middle !important; // font awesome styling appears first in the build and not in dev
`

const StyledLink = styled(Link)`
  text-decoration: none;
`

const StyledContainer = styled.div`
  margin-bottom: 5px;
  text-align: center;
`

const StyledBuyContainer = styled.div`
  margin-bottom: 5px;
  text-align: center;
  border: 1px solid #333;
  border-radius: 10px;
  padding: 7px;
  background: #f5c423;
`

const AmazonCTA = ({ onClick, href, title }) => {
  const handleOnClick = () => {
    if (onClick) {
      onClick()
    }
  }

  return (
    <StyledContainer>
      <StyledBuyContainer>
        <StyledLink
          href={href}
          title={title}
          target={"_blank"}
          rel={"noreferrer"}
          onClick={handleOnClick}
          color={"text"}
        >
          Buy on Amazon <StyledFontAwesomeIcon icon={faAmazon} />
        </StyledLink>
      </StyledBuyContainer>
    </StyledContainer>
  )
}

AmazonCTA.propTypes = {
  href: PropTypes.string.isRequired,
}

export default AmazonCTA
