import React from "react"
import AmazonBuyButton from "./base"
import { sendClickEvent } from "../../utils/googleAnalytics"

const actionText = "Buy Boots To Freedom on Amazon"

const sendGoogleEvent = () => {
  sendClickEvent({ label: actionText })
}

const BootsToFreedomAmazonBuyButton = () => (
  <AmazonBuyButton
    href="https://www.amazon.com/Boots-Freedom-journeys-seventies-different-ebook/dp/B08N6NHZFC/"
    title={actionText}
    onClick={sendGoogleEvent}
  />
)

export default BootsToFreedomAmazonBuyButton
