import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import GatsbyImage from "gatsby-image"
import { Link as PlainLink } from "gatsby"
import { Flex, Box } from "rebass"
import { faFacebook } from "@fortawesome/free-brands-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Link from "../link"
import { ExternalLink } from "../"
import { sendClickEvent } from "../../utils/googleAnalytics"

const StyledHeader = styled.h1`
  color: white;
  font-size: 1em;
  line-height: 1.3em;
  font-weight: normal;
  font-family: Source Sans Pro, Helvetica, Arial, sans-serif;
  margin: 0;
`

const StyledAvatar = styled(GatsbyImage)`
  border-radius: 50%;
  border: 5px solid #fff;
`
const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  color: white;
  zoom: 2;
  margin: 0.3em;
  text-decoration: none;
`
const handleFacebookClick = () => {
  sendClickEvent({ label: "Facebook header icon" })
}

const Header = ({ avatarImage }) => {
  return (
    <Box
      backgroundColor={"#333"}
      padding={3}
      fontFamily={"Source Sans Pro, Helvetica, Arial, sans-serif"}
    >
      <Flex flexWrap={"wrap"}>
        <Box width={[1 / 4]} textAlign={"center"}>
          <PlainLink to={"/"}>
            <StyledAvatar fixed={avatarImage} />
          </PlainLink>
        </Box>
        <Box width={[3 / 4]} pl={3}>
          <StyledHeader>
            I am <Link to={"/"}>Claude Tranchant</Link>. Follow me on my walking
            adventures with my two books
          </StyledHeader>
        </Box>
      </Flex>
      <Flex flexWrap={"wrap"}>
        <Box width={[1 / 4]} textAlign={"center"}>
          <ExternalLink
            href={"https://www.facebook.com/BootsToBliss/"}
            title="Follow Claude Tranchant on Facebook"
            onClick={handleFacebookClick}
          >
            <StyledFontAwesomeIcon icon={faFacebook} />
          </ExternalLink>
        </Box>
        <Box width={[3 / 4]} pl={3}>
          <Flex flexWrap={"wrap"}>
            <Box pt={2} pb={2}>
              <Link to={"/boots-to-bliss"} title={"Boots to Bliss"}>
                Boots to Bliss
              </Link>{" "}
            </Box>
          </Flex>
          <Flex flexWrap={"wrap"}>
            <Box pt={2} pb={2}>
              <Link to={"/boots-to-freedom"} title={"Boots to Freedom"}>
                Boots to Freedom
              </Link>
            </Box>
          </Flex>
        </Box>
      </Flex>
    </Box>
  )
}

Header.propTypes = {
  theme: PropTypes.object,
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
