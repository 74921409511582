import { Text } from "rebass"
import React from "react"

const Paragraph = ({ children }) => (
  <Text mb={3} textAlign={["justify", "left"]}>
    {children}
  </Text>
)

export default Paragraph
