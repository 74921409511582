import get from "lodash/get"

const separator = ":"

const stringifyObjectKeys = (object, keyPrefix = "") => {
  let arrayOfKeys = []
  for (let [key, value] of Object.entries(object)) {
    if (typeof value === "object" && value !== null && !Array.isArray(value)) {
      let nested = stringifyObjectKeys(value, `${key}.`)
      arrayOfKeys = [...arrayOfKeys, ...nested]
    } else {
      arrayOfKeys.push(`${keyPrefix}${key}`)
    }
  }

  return arrayOfKeys
}

export const to = properties => {
  const metaArray = []

  let keys = stringifyObjectKeys(properties)

  for (let key of keys) {
    let values = get(properties, key)
    if (values) {
      // convert single values into array to simplify adding content in the next step
      if (!Array.isArray(values)) {
        values = [values]
      }

      for (let content of values) {
        metaArray.push({
          property: `og:${key.replace(".", separator)}`,
          content,
        })
      }
    }
  }

  return metaArray
}
