import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { Box, Text } from "rebass"
import { Paragraph } from "../"

const StockingSoon = styled.div`
  padding: 0.5em;
  margin: 0.5em 0;
  border-radius: 7px;
  background: lightgray;
  color: #333333;
`
const BookStore = ({ name, address, phone, isStockingSoon }) => (
  <Box>
    <Text fontWeight={"bold"} fontSize={3}>
      {name}
    </Text>
    {isStockingSoon && <StockingSoon>Stocking Soon</StockingSoon>}
    <Paragraph>{address}</Paragraph>
    {phone && <Paragraph>{phone}</Paragraph>}
  </Box>
)

BookStore.defaultProps = {
  isStockingSoon: false,
}

BookStore.propTypes = {
  name: PropTypes.string.isRequired,
  address: PropTypes.string.isRequired,
  phone: PropTypes.string,
  isStockingSoon: PropTypes.bool.isRequired,
}

export default BookStore
