import React from "react"
import PropTypes from "prop-types"
import { Box } from "rebass"
import styled from "styled-components"

const StyledQuote = styled.span`
  font-size: 2.5em;
  line-height: 0;
  position: relative;
  top: 0.2em;
  padding-right: 0.1em;
`
const StyledByLine = styled.p`
  text-align: right;
`
const Testimonial = ({ by, children }) => (
  <>
    <Box fontStyle={"italic"}>
      <StyledQuote>&ldquo;</StyledQuote>
      {children}
      <StyledQuote>&rdquo;</StyledQuote>
    </Box>
    <StyledByLine>{by}</StyledByLine>
  </>
)

Testimonial.propTypes = {
  by: PropTypes.string,
  children: PropTypes.any.isRequired,
}

export default Testimonial
