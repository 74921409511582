import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"
import GatsbyImage from "gatsby-image"
import BackgroundImage from "gatsby-background-image"
import { Flex, Box } from "rebass"
import Link from "../link"
import { Link as PlainLink } from "gatsby"
import { faFacebook } from "@fortawesome/free-brands-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { ExternalLink } from "../"
import { sendClickEvent } from "../../utils/googleAnalytics"

const StyledBackgroundImage = styled(BackgroundImage)`
  height: 100vh;
  padding: 2em;
  opacity: 0.7;
`

const StyledHeader = styled.h1`
  color: white;
  font-size: 1.4em;
  line-height: 1.6em;
  font-weight: normal;
  text-align: center;
`

const StyledAvatar = styled(GatsbyImage)`
  border-radius: 50%;
  border: 5px solid #fff;
`
const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  color: white;
  zoom: 1.8;
  text-decoration: none;
`
const handleFacebookClick = () => {
  sendClickEvent({ label: "Facebook header icon" })
}

const StyledBreak = styled.br``

const Header = ({ backgroundImage, avatarImage }) => {
  return (
    <StyledBackgroundImage Tag={"header"} fluid={backgroundImage}>
      <Flex
        flexWrap={"wrap"}
        sx={{
          height: ["100%"],
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <PlainLink to={"/"}>
          <StyledAvatar fixed={avatarImage} />
        </PlainLink>
        <StyledHeader>
          I am{" "}
          <Link to={"/"} title={"Claude Tranchant - author and traveller"}>
            Claude Tranchant
          </Link>
          . Follow me on my walking adventures with my two books
          <StyledBreak />
          <Link
            to={"/boots-to-bliss"}
            title={"Boots to Bliss by Claude Tranchant"}
          >
            Boots to Bliss
          </Link>{" "}
          <br />
          and
          <StyledBreak />
          <Link
            to={"/boots-to-freedom"}
            title={"Boots to Freedom by Claude Tranchant"}
          >
            Boots to Freedom
          </Link>
          <Box mt={4}>
            <ExternalLink
              href={"https://www.facebook.com/BootsToBliss/"}
              title="Follow Claude Tranchant on Facebook"
              onClick={handleFacebookClick}
            >
              <StyledFontAwesomeIcon icon={faFacebook} />
            </ExternalLink>
          </Box>
        </StyledHeader>
      </Flex>
    </StyledBackgroundImage>
  )
}

Header.propTypes = {
  theme: PropTypes.object,
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
