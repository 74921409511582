/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { ThemeProvider } from "theme-ui"
import theme from "./theme"
import { Flex, Box } from "rebass"
import { Desktop as DesktopHeader, Mobile as MobileHeader } from "./header"
import { createGlobalStyle } from "styled-components"
import { graphql, useStaticQuery } from "gatsby"

const GlobalStyle = createGlobalStyle`
  body {
    background: #fff;
    font-family: "Source Sans Pro, Helvetica, Arial, sans-serif";
  }
`

const Layout = ({ withHeader, forceDesktopHeader, children }) => {
  const data = useStaticQuery(graphql`
    query HeaderQuery {
      headerImage: file(
        relativePath: { eq: "boots-to-freedom-cover-photo-dark.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      avatar: file(relativePath: { eq: "claude-tranchant.jpg" }) {
        childImageSharp {
          fixed(width: 165, height: 165) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      avatarMobile: file(relativePath: { eq: "claude-tranchant.jpg" }) {
        childImageSharp {
          fixed(width: 70, height: 70) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  const mobileHeaderDisplayProp = forceDesktopHeader ? "none" : ["auto", "none"]
  const desktopHeaderDisplayProp = forceDesktopHeader
    ? "block"
    : ["none", "block"]

  return (
    <>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <Flex flexWrap="wrap" sx={{ minHeight: "100vh" }}>
          {withHeader && (
            <>
              <Box display={mobileHeaderDisplayProp}>
                <MobileHeader
                  avatarImage={data.avatarMobile.childImageSharp.fixed}
                />
              </Box>
              <Box
                variant={"box.leftHeader"}
                display={desktopHeaderDisplayProp}
              >
                <DesktopHeader
                  backgroundImage={data.headerImage.childImageSharp.fluid}
                  avatarImage={data.avatar.childImageSharp.fixed}
                />
              </Box>
            </>
          )}
          <Box
            fontFamily={"body"}
            variant={withHeader ? "box.body" : "box.bodyOnly"}
          >
            <main>{children}</main>
          </Box>
        </Flex>
      </ThemeProvider>
    </>
  )
}

Layout.defaultProps = {
  withHeader: true,
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  withHeader: PropTypes.bool,
  forceDesktopHeader: PropTypes.bool,
}

export default Layout
