export default {
  breakpoints: ["35em", "52em", "64em"],
  colors: {
    text: "#333",
    light: "#666",
    extraLight: "#e2e2e2",
    emphasis: "#cb07ed",
    background: "white",
  },
  fonts: {
    body: "Source Sans Pro, Helvetica, Arial, sans-serif",
    heading: "Source Sans Pro, Helvetica, Arial, sans-serif",
  },
  box: {
    leftHeader: {
      width: ["100%", "40%", "25%"],
      height: ["100vh"],
      background: "white",
      fontSize: ["80%", "100%"],
    },
    body: {
      width: ["100%", "60%", "75%"],
      color: "text",
      p: [20, 30],
      pt: 50,
      height: ["inherit", "100vh"],
      overflowY: ["auto", "scroll"],
      background: "white",
    },
    bodyOnly: {
      width: "100%",
      color: "text",
      background: "white",
      height: ["inherit", "100vh"],
      p: [20, 30],
      pt: 50,
    },
  },
}
