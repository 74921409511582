import buyCallToAction from "./buyCallToAction"
import AmazonBuyButtons from "./amazonBuyButton"
import paragraph from "./paragraph"
import bookStore from "./bookStore"
import testimonial from "./testimonial"
import externalLink from "./externalLink"

export const {
  BootsToBlissAmazonBuyButton,
  BootsToFreedomAmazonBuyButton,
} = AmazonBuyButtons

export const BuyCallToAction = buyCallToAction
export const Paragraph = paragraph
export const BookStore = bookStore
export const Testimonial = testimonial
export const ExternalLink = externalLink
