import React from "react"
import AmazonBuyButton from "./base"
import { sendClickEvent } from "../../utils/googleAnalytics"

const actionText = "Buy Boots To Bliss on Amazon"

const sendGoogleEvent = () => {
  sendClickEvent({ label: actionText })
}

const BootsToBlissAmazonBuyButton = () => (
  <AmazonBuyButton
    href="http://www.amazon.com/Boots-to-Bliss-ebook/dp/B00EQT1INE/"
    title={actionText}
    onClick={sendGoogleEvent}
  />
)

export default BootsToBlissAmazonBuyButton
