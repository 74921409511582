import React from "react"
import Link from "../link"
import { Box, Text } from "rebass"

const BuyCTA = ({ amazonButton, ...props }) => (
  <Box textAlign={"center"} {...props}>
    {amazonButton}
    <Text color={"light"}>
      or in these <br />
      <Link to={"/australian-book-stores"}>Australian Book Stores</Link>
    </Text>
  </Box>
)

export default BuyCTA
