/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import { to as toOpenGraphMeta } from "../utils/openGraphMeta"
import { to as toTwitterGraph } from "../utils/twitterGraph"

function SEO({
  description,
  lang,
  meta,
  title,
  openGraph,
  twitter,
  canonicalUrl,
}) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description
  const twitterMeta = twitter
    ? toTwitterGraph({
        ...twitter,
        card: "summary_large_image",
      })
    : []
  const openGraphMeta = openGraph ? toOpenGraphMeta(openGraph.meta) : []
  return (
    <Helmet
      htmlAttributes={{
        lang,
        ...(openGraph && { prefix: openGraph.namespace }),
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
      ]
        .concat(meta)
        .concat(twitterMeta)
        .concat(openGraphMeta)}
    >
      (canonicalUrl && <link rel={"canonical"} href={canonicalUrl} />)
      <script
        async
        src={`https://www.googletagmanager.com/gtag/js?id=${process.env.GA_TRACKING_ID}`}
      ></script>
      <script>
        {`
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());
  gtag('config', '${process.env.GA_TRACKING_ID}', {
    "transport_type": !!navigator.sendBeacon ? "beacon" : "xhr",
    "send_page_view": false,
    "custom_map": {
      "${process.env.GA_VERSION_DIMENSION}": "appVersion"
    } 
  });
`}
      </script>
      <script
        type="text/javascript"
        src="https://platform-api.sharethis.com/js/sharethis.js#property=5f9c70cafade1a00127896b1&product=inline-share-buttons"
        async="async"
      ></script>
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
