import React from "react"
import styled from "styled-components"
const StyledAnchor = styled.a`
  color: ${props => props.colour || "#333"};
  text-decoration: none;
`

const externalLink = ({ href, title, children, onClick, colour }) => {
  const handleOnClick = event => {
    if (onClick) {
      onClick(event)
    }
  }

  return (
    <StyledAnchor
      href={href}
      title={title}
      target={"_blank"}
      rel={"noreferrer"}
      onClick={handleOnClick}
      colour={colour}
    >
      {children}
    </StyledAnchor>
  )
}

export default externalLink
